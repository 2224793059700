import getConfig from 'next/config';
const { publicRuntimeConfig: env } = getConfig();

export const APP_MODE = env.appMode;
export const BASE64_BASIC_AUTH = env.base64BasicAuth;

export const BASE_API = env.baseApi;
export const CLIENT_API = env.clientApi;
export const CSRF_API = env.csrfApi;
export const DASHBOARD_API = env.dashboardApi;
export const USER_API = env.userApi;
export const USER_BO_API = env.userBoApi;
export const LOGIN_API = env.loginApi;
export const LOGOUT_API = env.logoutApi;
export const PROFILE_API = env.profileApi;
export const PARTNER_API = env.partnerApi;
export const PARTNER_USER_API = env.partnerUserApi;
export const PARTNER_CHANNEL_API = env.partnerChannelApi;
export const PARTNER_ROUTE_API = env.partnerRouteApi;
export const INTEGRATION_API = env.integrationApi;
export const NETWORK_API = env.networkApi;
export const TRANSACTION_API = env.transactionApi;
export const FEE_API = env.feeApi;
export const BANK_API = env.bankApi;
export const CHANNEL_API = env.channelApi;
export const CHANNEL_MAPPING_API = env.channelMappingApi;
export const CHANNEL_LIST_API = env.channelListApi;
export const REPORTING_API = env.reportingApi;
export const BO_API = env.boApi;
export const GENERAL_API = env.generalApi;
export const STATEMENTS_API = env.statementsApi;
export const ONBOARD_API = env.onboardApi;
export const CALLBACK_API = env.callbackApi;
export const SETTLEMENT_API = env.settlementApi;
export const CHECK_RRN_API = env.checkRrnApi;
export const PAZEMO_BALANCE_API = env.pazemoBalanceApi;
export const BASE_URL = env.baseUrl;
export const BUSINESS_TYPE_API = env.businessTypeApi;
export const BUSINESS_CRITERIA_API = env.businessCriteriaApi;
export const MERCHANT_BANK_API = env.merchantBankApi;
export const PROVINSI_API = env.provinsiApi;
export const KABUPATEN_API = env.kabupatenApi;
export const KECAMATAN_API = env.kecamatanApi;
export const DESA_API = env.desaApi;
export const MERCHANT_GENERAL_API = env.merchantGeneralApi;
export const CHECK_STATUS_REQUEST_API = env.checkStatusRequestApi;
export const MERCHANT_LIST_API = env.merchantListApi;
export const MERCHANT_DETAIL_API = env.merchantDetailApi;
export const CAPTCHA_SITE_KEY = env.captchaSiteKey;
export const BASE_API2 = env.baseApi2;
export const BUSINESS_TYPE_API2 = env.businessTypeApi2;
export const BUSINESS_INDUSTRY_API = env.businessIndustryApi;
export const COUNTRY_API = env.countryApi;
export const PROVINCE_API = env.provinceApi;
export const CITY_API = env.cityApi;
export const DISTRICT_API = env.districtApi;
export const SUBDISTRICT_API = env.subdistrictApi;
export const ONBOARDING_API = env.onboardingApi;
export const UPLOAD_API = env.uploadApi;
export const ONBOARD_UPLOAD_GENERAL_API = env.onboardUploadGeneralApi;
export const ONBOARD_GET_IMAGE_API = env.onboardGetImageApi;
